<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Días festivos" :exportInfo="items">
      <template v-slot:buttons>
        <Basic-Btn text="Agregar" color="primary" icon="mdi-plus" @click="dialogs.new = true" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template
            ><span>Editar</span></v-tooltip
          >
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="moveRest(item.iddia_festivo)">mdi-refresh</v-icon></template
            ><span>Recorrer Descansos (Sábados)</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.iddia_festivo)">mdi-delete</v-icon></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <Dialog-Form :estate="dialogs.new" title="Nuevo día festivo">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="save()" :loading="loading" />
      </template>
      <v-form ref="formNew" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="forms.new.nombre" label="Nombre" prepend-icon="mdi-account-group" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
          <v-col cols="12">
            <Date-Picker v-model="forms.new.fecha_calendario" label="Fecha" offLimit/>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.update" title="Actualizar día festivo">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="update()" :loading="loading" />
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="forms.update.nombre" label="Nombre" prepend-icon="mdi-account-group" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
          <v-col cols="12">
            <Date-Picker v-model="forms.update.fecha_calendario" label="Fecha" offYear offLimit/>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Delete-Message :estate="dialogs.rest" msg="¿Realmente desea recorrer los descansos (Sábados)?">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Aceptar" color="success" icon="mdi-check" @click="moveRest()" :loading="loading" />
      </template>
    </Delete-Message>
    <Delete-Message :estate="dialogs.remove" msg="¿Realmente desea eliminar el día festivo?">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Eliminar" color="error" icon="mdi-check" @click="remove()" :loading="loading" />
      </template>
    </Delete-Message>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import moment from "moment";
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DatePicker, DeleteMessage } from "@/components";

export default {
  name: "group",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DatePicker,
    DeleteMessage,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    headers: [
      { text: "ID", align: "left", sortable: true, value: "iddia_festivo" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre" },
      { text: "Fecha", align: "left", sortable: true, value: "fecha_calendario" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    items: [],
    users: [],
    selected: [],
    deleted: null,
    moved: null,
    forms: {
      new: {
        nombre: "",
        fecha_calendario: "",
      },
      update: {
        iddia_festivo: "",
        nombre: "",
        fecha_calendario: "",
      },
    },
    rules: {
      nombre: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 200) || "El campo excede la longitud máxima"],
    },
    dialogs: {
      new: false,
      update: false,
      rest: false,
      remove: false,
    },
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.catalog + '/holiday?c=iddia_festivo&c=nombre&c=fecha_calendario')
        .then((response) => {
          this.items = this.orderDays(response.data.data);
        })
        .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        for (let key in this.forms.new) {
          this.forms.new[key] = "";
        }
        this.dialogs.new = false;
      } else if (this.dialogs.update) {
        this.dialogs.update = false;
      } else if (this.dialogs.rest) {
        this.dialogs.rest = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      }
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.new));
        this.axios
          .post(services.routes.catalog + '/holiday', { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Día festivo creado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    view(data) {
      this.forms.update = JSON.parse(JSON.stringify(data));
      this.forms.update.fecha_creacion = this.dateFormat(data.fecha_creacion)
      this.forms.update.fecha_actualizacion = this.dateFormat(data.fecha_actualizacion)
      this.dialogs.update = true;
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .put(services.routes.catalog + '/holiday', { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Día festivo actualizado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    moveRest(id) {
      if (!id) {
        this.loading = true;
        let data = {
          iddia_festivo: this.moved
        }
        this.axios
          .post(services.routes.moveRest, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Descansos recorridos exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.moved = id;
        this.dialogs.rest = true;
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        this.axios
          .delete(services.routes.catalog + '/holiday', { data: { data: { iddia_festivo: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Día festivo eliminado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
    orderDays(object) {
      let order = [];
      if (object) {
        order = object.sort(function (a, b) {
          if ( new Date(a.fecha_calendario).getTime() > new Date(b.fecha_calendario).getTime()) {
            return -1;
          }
        });
      }
      return order;
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped></style>
